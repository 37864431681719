import React, { Component } from "react"
import Image from "../components/image"

class ImageText extends Component {
    render() {
        return (
            <div className="ImageText">
                <div className="ImageText-wrapper">
                    <div className="ImageText-image">
                        <div style={{ maxWidth: `330px` }}>
                            <Image />
                        </div>
                    </div>
                    <div className="ImageText-text">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

export default ImageText