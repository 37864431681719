import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/Content"
import ImageText from "../components/ImageText"

const IndexPage = () => (
  <Layout>
    <SEO title="About" />
    <Content flexCenter={true}>

      <ImageText>
        <h1>Hello Open World!</h1>
        <p>
          My name’s <span className="yellow">Niko Schmidt</span><span className="gray">.Germany(<span className="pink">23</span>)</span>;
          <br></br>
          I’m a creative and ambitious web developer && designer.
          <br></br>
          <span className="orange">HTML</span> + <span className="orange">SASS</span> + <span className="orange">JavaScript</span> => <span className="orange"><a target="_blank" href="https://figma.com">Figma</a></span> & (Pen and Paper) are my tools.
        </p>
        <em>
          /*<br></br>
          This Website is made with <a target="_blank" href="https://www.gatsbyjs.org">GatsbyJS</a>.
          <br></br>*/
        </em>

      </ImageText>
      
    </Content>
  </Layout>
)

export default IndexPage
